import '../Style/AccountFlag.css';
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function AccountFlag({setFlag, type}){

    const {userData} = useContext(UserDataContext);  

    const output = (
        <div id="AccountFlag">
            <div className='message-card'>
                {/* <p className="close-flag" onClick={()=>setFlag(false)}>
                    &#10006;
                </p> */}
                <h1>
                    Account Suspended !!
                </h1>
          
                {userData && type =='Account' &&(
                    <p className="flag-message">
                        Dear {userData.name}
                        <br/> <br/>
                        Your account has been suspended as a result of multiple reports regarding its activity.
                        Upon reaching the established threshold for such reports, our system initiated a review 
                        process in accordance with our terms & conditions.
                        <br/> <br/>
                        This action has been taken to ensure the safety and integrity of our platform. 
                        If you believe this suspension is a mistake, you have the option to appeal the decision. 
                        Please contact our support team at scheduleeaseee@gmail.com to initiate the 
                        review process.
                        <br/> <br/>
                        We encourage you to review our terms & consitions here: 
                        <br/>
                        <Link to='/terms' className="terms">Terms & Conditions</Link>
                        <br/> <br/>
                        Thank you for your understanding.
                        <br/> <br/>
                        Sincerely,
                        <br/>
                        ScheduleEase (PTY) LTD
                    </p>
                )}
                {userData && type =='Servicer' &&(
                    <p className="flag-message">
                        Dear {userData.name}
                        <br/> <br/>
                        This servicer's account has been suspended as a result of multiple reports regarding their activity.
                        Upon reaching the established threshold for such reports, our system initiated a review 
                        process in accordance with our terms & conditions.
                        <br/> <br/>
                        Their suspension was in accordance with our terms & consitions: 
                        <br/>
                        <Link to='/terms' className="terms">Terms & Conditions</Link>
                        <br/> <br/>
                        Thank you for your understanding.
                        <br/> <br/>
                        Sincerely,
                        <br/>
                        ScheduleEase (PTY) LTD
                    </p>
                )}
            </div>
        </div>
    );

    return output;
}