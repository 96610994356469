import '../Style/Search.css'
import Thumbnail2 from '../Components/Thumbnail2'
import SlideShow from '../Components/SlideShow';
import { useRef,useState, useContext, useEffect } from 'react';
import { createSearch } from '../Actions/Search';
import { UserDataContext } from "../Data/Session";
import LocationSelector from "../Components/LocationSelector";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function Search(){
    const linkN = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const linkP = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const [search, setSearch] = useState(null);
    const [filtersOpen, setFilters] = useState('0px 1fr');
    const {userData, servicers} = useContext(UserDataContext);
    const [results, setResults] = useState({house:[],pool:[],car:[],plumber:[],electrician:[],gardener:[],painter:[],indoor:[], outdoor:[],pet:[],pest:[],waste:[],gutter:[]});
    const navigate = useNavigate();

    const indoor = ["House Cleaner","Electrician","Plumber","Painter"];
    const outdoor = ["Car Cleaner","Pool Cleaner","Gardener","Pest Controller","Gutter Cleaner","Waste Collector","Pet Caretaker","Painter"];

    useEffect(()=>{
        if(!userData){
            navigate('/signIn')
        }
    },[userData])

    const handleChange= (e)=>{
        e.preventDefault();
        const {value} = e.target;
        setSearch(value);
    }

    function Prev(){
        for(let i=0; i<13; i++){
            linkP[i].current.click();
        }
    }

    function Next(){
        for(let i=0; i<13; i++){
            linkN[i].current.click();
        }
    }

    function Searcher(input){
        createSearch({searcherId:userData._id, search:input, createdDate: new Date().getTime()})
    }

    useEffect(()=>{
        if(search && userData && userData.type=='User' && userData.city){
            
            const data = servicers.filter((servicer)=>(servicer.idNumber && servicer.city && servicer.city==userData.city && (servicer.flagged==null || servicer.flagged==false) && ( (servicer.name+' '+servicer.surname).toLowerCase().includes(search.toLowerCase()) || servicer.service.toLowerCase().includes(search.toLowerCase()) || (search.toLowerCase()=='indoor services' && indoor.includes(servicer.service)) || (search.toLowerCase()=='outdoor services' && outdoor.includes(servicer.service)) ) ));
            let categorisedData = {house:[],pool:[],car:[],plumber:[],electrician:[],gardener:[],painter:[],indoor:[], outdoor:[],pet:[],pest:[],waste:[],gutter:[],window:[]}
            for(let i=0; i<data.length; i++){
                if(data[i].service=='House Cleaner' || data[i].service=='Plumber' ||  data[i].service=='Electrician' || data[i].service=='Painter'){
                    categorisedData.indoor = [...categorisedData.indoor,data[i]]
                }
                else if( data[i].service=='Pool Cleaner' ||  data[i].service=='Gutter Cleaner' ||  data[i].service=='Car Cleaner' || data[i].service=='Gardener' || data[i].service=='Pest Controller' || data[i].service=='Painter' || data[i].service=='Waste Collector'){
                    categorisedData.outdoor = [...categorisedData.outdoor,data[i]]
                }
                if( data[i].service=='House Cleaner'){
                    categorisedData.house = [...categorisedData.house,data[i]]
                }
                else if( data[i].service=='Plumber'){
                    categorisedData.plumber = [...categorisedData.plumber,data[i]]
                }
                else if( data[i].service=='Electrician'){
                    categorisedData.electrician = [...categorisedData.electrician,data[i]]
                }
                else if( data[i].service=='Gardener'){
                    categorisedData.gardener = [...categorisedData.gardener,data[i]]
                }
                else if( data[i].service=='Pool Cleaner'){
                    categorisedData.pool = [...categorisedData.pool,data[i]]
                }
                else if( data[i].service=='Gutter Cleaner'){
                    categorisedData.gutter = [...categorisedData.gutter,data[i]]
                }
                else if( data[i].service=='Car Cleaner'){
                    categorisedData.car = [...categorisedData.car,data[i]]
                }
                else if( data[i].service=='Pet Caretaker'){
                    categorisedData.pet = [...categorisedData.pet,data[i]]
                }
                else if( data[i].service=='Pest Contoller'){
                    categorisedData.pest = [...categorisedData.pest,data[i]]
                }
                else if( data[i].service=='Painter'){
                    categorisedData.painter = [...categorisedData.painter,data[i]]
                }
                else if( data[i].service=='Waste Collector'){
                    categorisedData.waste = [...categorisedData.waste,data[i]]
                }
            }
            setResults(categorisedData);
        }
        else if(search && userData && userData.type=='Servicer'){
            
            const data = servicers.filter((servicer)=>( servicer.idNumber && (servicer.flagged==null || servicer.flagged==false) && ( (servicer.name+' '+servicer.surname).toLowerCase().includes(search.toLowerCase()) || servicer.service.toLowerCase().includes(search.toLowerCase()) || (search.toLowerCase()=='indoor services' && indoor.includes(servicer.service)) || (search.toLowerCase()=='outdoor services' && outdoor.includes(servicer.service)) )));
           
            let categorisedData = {house:[],pool:[],car:[],plumber:[],electrician:[],gardener:[],painter:[],indoor:[], outdoor:[],pet:[],pest:[],waste:[],gutter:[]}
            for(let i=0; i<data.length; i++){
                if(data[i].service=='House Cleaner' || data[i].service=='Plumber' ||  data[i].service=='Electrician' || data[i].service=='Painter'){
                    categorisedData.indoor = [...categorisedData.indoor,data[i]]
                }
                else if( data[i].service=='Pool Cleaner' ||  data[i].service=='Gutter Cleaner' ||  data[i].service=='Car Cleaner' || data[i].service=='Gardener' || data[i].service=='Pest Controller' || data[i].service=='Painter' || data[i].service=='Waste Collector'){
                    categorisedData.outdoor = [...categorisedData.outdoor,data[i]]
                }
                if( data[i].service=='House Cleaner'){
                    categorisedData.house = [...categorisedData.house,data[i]]
                }
                else if( data[i].service=='Plumber'){
                    categorisedData.plumber = [...categorisedData.plumber,data[i]]
                }
                else if( data[i].service=='Electrician'){
                    categorisedData.electrician = [...categorisedData.electrician,data[i]]
                }
                else if( data[i].service=='Gardener'){
                    categorisedData.gardener = [...categorisedData.gardener,data[i]]
                }
                else if( data[i].service=='Pool Cleaner'){
                    categorisedData.pool = [...categorisedData.pool,data[i]]
                }
                else if( data[i].service=='Gutter Cleaner'){
                    categorisedData.gutter = [...categorisedData.gutter,data[i]]
                }
                else if( data[i].service=='Car Cleaner'){
                    categorisedData.car = [...categorisedData.car,data[i]]
                }
                else if( data[i].service=='Pet Caretaker'){
                    categorisedData.pet = [...categorisedData.pet,data[i]]
                }
                else if( data[i].service=='Pest Contoller'){
                    categorisedData.pest = [...categorisedData.pest,data[i]]
                }
                else if( data[i].service=='Painter'){
                    categorisedData.painter = [...categorisedData.painter,data[i]]
                }
                else if( data[i].service=='Waste Collector'){
                    categorisedData.waste = [...categorisedData.waste,data[i]]
                }
            }
            setResults(categorisedData);
        }
    },[search])

    const output = (<div id="Search" style={{gridTemplateColumns: filtersOpen}}>
                        {filtersOpen=='0px 1fr' &&(
                            <button className='filtersButton' onClick={()=>setFilters('290px 1fr')}>Filters &#8594;</button>
                        )}
                        {filtersOpen=='290px 1fr' &&(
                            <button className='filtersButton' onClick={()=>setFilters('0px 1fr')}>&#8592; Close</button>
                        )}
                        <div className='left-block'>
                            <div className="vertical-slideshow">
                                    <div className="slide-top"><div onClick={Prev}><img src="/Images/up-white.png"></img></div></div>
                                    <div className="slide-middle">
                                        <Thumbnail2 linkN={linkN[0]} linkP={linkP[0]} category={'House Cleaner'} total={13} pos={0} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[1]} linkP={linkP[1]} category={'Pool Cleaner'} total={13} pos={1} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[2]} linkP={linkP[2]} category={'Car Cleaner'} total={13} pos={2} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[3]} linkP={linkP[3]} category={'Plumber'} total={13} pos={3} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[4]} linkP={linkP[4]} category={'Electrician'} total={13} pos={4} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[5]} linkP={linkP[5]} category={'Gardener'} total={13} pos={5} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[6]} linkP={linkP[6]} category={'Painter'} total={13} pos={6} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[7]} linkP={linkP[7]} category={'Indoor Services'} total={13} pos={7} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[8]} linkP={linkP[8]} category={'Outdoor Services'} total={13} pos={8} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[9]} linkP={linkP[9]} category={'Pet Caretaker'} total={13} pos={9} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[10]} linkP={linkP[10]} category={'Pest Controller'} total={13} pos={10} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[11]} linkP={linkP[11]} category={'Waste Remover'} total={13} pos={11} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                        <Thumbnail2 linkN={linkN[12]} linkP={linkP[12]} category={'Gutter Cleaner'} total={13} pos={12} Search={Searcher} setSearch={setSearch}></Thumbnail2>
                                    </div>
                                    <div className="slide-bottom"><div onClick={Next}><img src="/Images/down-white.png"></img></div></div>
                            </div>
                        </div>
                        <div className='right-block'>
                            <div className='search-tab'>
                                <input className='search-input' value={search} type='text' onChange={handleChange} ></input>
                                <div className='search-button' onClick={()=>Searcher(search)}>
                                    <img src='/Images/searchIcon2.png'></img>
                                </div>
                            </div>
                            <div className='search-results'>
                                {search!=null && results.house.length==0 && results.pool.length==0 && results.car.length==0 && results.plumber.length==0 && results.electrician.length==0 && results.gardener.length==0 && results.painter.length==0 && results.indoor.length==0 && results.outdoor.length==0 && results.pet.length==0 && results.pest.length==0 && results.waste.length==0 && results.gutter.length==0 &&(
                                    <h1>No matching results</h1>
                                )}
                                {search!=null && !(results.house.length==0 && results.pool.length==0 && results.car.length==0 && results.plumber.length==0 && results.electrician.length==0 && results.gardener.length==0 && results.painter.length==0 && results.indoor.length==0 && results.outdoor.length==0 && results.pet.length==0 && results.pest.length==0 && results.waste.length==0 && results.gutter.length==0 ) &&(
                                    <h1>Results</h1>
                                )}
                                {search==null &&(
                                    <h1>Awaiting search ...</h1>
                                )}
                                {results.house.length>0 && (<>
                                    <SlideShow data={results.house} ></SlideShow>
                                </>)}
                                {results.pool.length>0 && (<>
                                    <SlideShow data={results.pool} ></SlideShow>
                                </>)}
                                {results.car.length>0 && (<>
                                    <SlideShow data={results.car} ></SlideShow>
                                </>)}
                                {results.plumber.length>0 && (<>
                                    <SlideShow data={results.plumber} ></SlideShow>
                                </>)}
                                {results.electrician.length>0 && (<>
                                    <SlideShow data={results.electrician} ></SlideShow>
                                </>)}
                                {results.gardener.length>0 && (<>
                                    <SlideShow data={results.gardener} ></SlideShow>
                                </>)}
                                {results.painter.length>0 && (<>
                                    <SlideShow data={results.painter} ></SlideShow>
                                </>)}
                                {results.pet.length>0 && (<>
                                    <SlideShow data={results.pet} ></SlideShow>
                                </>)}
                                {results.pest.length>0 && (<>
                                    <SlideShow data={results.pest} ></SlideShow>
                                </>)}
                                {results.waste.length>0 && (<>
                                    <SlideShow data={results.waste} ></SlideShow>
                                </>)}
                                {results.gutter.length>0 && (<>
                                    <SlideShow data={results.gutter} ></SlideShow>
                                </>)}
                            </div>
                        </div>
                        {/* <LocationSelector></LocationSelector> */}
                    </div>);
    return output;
}