import React, { useState, useEffect, useContext } from 'react';
import { UserDataContext } from "../Data/Session.js";
import { GoogleMap, Marker, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';
import '../Style/Directions.css';

export default function Directions({ setOpen, type, servicerLocation, targetLocation, booking}) {
    const [currentLocation, setCurrentLocation] = useState(null);
    const { userData } = useContext(UserDataContext);
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAWeu4o32HE1PPxvn3ABy5uHGU4L5a29O4", // Replace with your Google Maps API Key
    });

    useEffect(() => {
        if(type === "servicer") {
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                    // console.log("Current Location Set 1")
                },
                (error) => {
                    console.error("Error fetching current location:", error);
                },
                { enableHighAccuracy: true }
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }else if(type === "user" && servicerLocation) {
            setCurrentLocation(servicerLocation);
            // console.log("Current Location Set 2")
        }
    }, [type, servicerLocation]);

    useEffect(() => {
        const fetchDirections = async () => {
            if (!currentLocation || !targetLocation || !isLoaded || !booking) return;
            
            const directionsService = new window.google.maps.DirectionsService();
            const results = await directionsService.route({
                origin: currentLocation,
                destination: `${booking.address.line1+' '+booking.address.line2} ${targetLocation}`,
                travelMode: window.google.maps.TravelMode.DRIVING,
            });
            setDirectionsResponse(results);
            // console.log("Directions Fetched")
        };
        // console.log("Fetching Directions......")
        fetchDirections();
    }, [currentLocation, targetLocation, booking]);

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div id="Directions">
             <div className="directions-block">
                <p className="close-button" onClick={() => setOpen(false)}>&#10006;</p>
                <div className='address-body'>
                    <p>
                        Building Type: 
                        <br/>
                        <span>{(booking)?booking.address.buildingType:''}</span>
                    </p>
                    <div className='side-by-side'>
                        <p>
                            Str No: 
                            <br/>
                            <span>{(booking)?booking.address.line1:''}</span>
                        </p>
                        <p>
                            Street Name:
                            <br/>
                            <span>{(booking)?booking.address.line2:''}</span>
                        </p>
                    </div>
                    {booking && booking.address.buildingType=='Apartment' && (<>
                        <div className='side-by-side'>
                            <p>
                                Unit No:
                                <br/>
                                <span>{(booking)?booking.address.line3:''}</span>
                            </p>
                            <p>
                                Apartment Name:
                                <br/>
                                <span>{(booking)?booking.address.line4:''}</span>
                            </p>
                        </div>
                    </>)}
                    {booking && booking.address.buildingType=='Complex / Estate' && (<>
                        <p>
                            Complex / Estate Name:
                            <br/>
                            <span>{(booking)?booking.address.line3:''}</span>
                        </p>
                    </>)}
                    {booking && booking.address.buildingType=='Office' && (<>
                        <div className='side-by-side'>
                            <p>
                                Floor No:
                                <br/>
                                <span>{(booking)?booking.address.line3:''}</span>
                            </p>
                            <p>
                                Company Name, Building Name:
                                <br/>
                                <span>{(booking)?booking.address.line4:''}</span>
                            </p>
                        </div>
                    </>)}
                    <p>
                        Note:
                        <br/>
                        <span>{(booking)?booking.address.note:''}</span>
                    </p>
                </div>
                <div className="map-body"> 
                    <GoogleMap
                        center={currentLocation || { lat: 51.505, lng: -0.09 }}
                        zoom={13}
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                    >
                        {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>
                </div>
            </div>
        </div>
    );
}
