import '../Style/CarCleaning.css';
import { useState } from 'react';

export default function CarCleaning(props){
    //Car Types
    const [border1, setBorder1] = useState('2px solid white');
    const [border2, setBorder2] = useState('2px solid black');
    const [border3, setBorder3] = useState('2px solid black');
    const [border4, setBorder4] = useState('2px solid black');

    //Clean Interior
    const [border5, setBorder5] = useState('2px solid black');
    const [border6, setBorder6] = useState('2px solid white');

    //Polish Tyres
    const [border7, setBorder7] = useState('2px solid black');
    const [border8, setBorder8] = useState('2px solid white');

    //Car Type
    const [filler1, setFiller1] = useState('black');
    const [filler2, setFiller2] = useState('white');
    const [filler3, setFiller3] = useState('white');
    const [filler4, setFiller4] = useState('white');

    //Clean Interior
    const [filler5, setFiller5] = useState('white');
    const [filler6, setFiller6] = useState('black');

    //Polish Tyres
    const [filler7, setFiller7] = useState('white');
    const [filler8, setFiller8] = useState('black');

function CarType(type){
    const newForms = [...props.forms];
    const interior = newForms[props.index].cleanInterior;
    const tyres = newForms[props.index].polishTyres;
    let newSubtotal = 0;

    if(type==1){
        newForms[props.index].carType = 'Light Vehicle';
        newForms[props.index].subTime = 0.5;
        newSubtotal += 80;
        setBorder1('2px solid white');
        setBorder2('2px solid black');
        setBorder3('2px solid black');
        setBorder4('2px solid black');
        setFiller1('black');
        setFiller2('white');
        setFiller3('white');
        setFiller4('white');
    }
    else if(type==2){
        newForms[props.index].carType = '2 Axle Heavy Vehicle';
        newForms[props.index].subTime = 1;
        newSubtotal += 120;
        setBorder1('2px solid black');
        setBorder2('2px solid white');
        setBorder3('2px solid black');
        setBorder4('2px solid black');
        setFiller1('white');
        setFiller2('black');
        setFiller3('white');
        setFiller4('white');
    }
    else if(type==3){
        newForms[props.index].carType = '3 & 4 Axle Heavy Vehicle';
        newForms[props.index].subTime = 1;
        newSubtotal += 250;
        setBorder1('2px solid black');
        setBorder2('2px solid black');
        setBorder3('2px solid white');
        setBorder4('2px solid black');
        setFiller1('white');
        setFiller2('white');
        setFiller3('black');
        setFiller4('white');
    }
    // else if(type==4){
    //     newForms[props.index].carType = '5 & more Axle Heavy Vehicle';
    //     newForms[props.index].subTime = 1;
    //     newSubtotal += 350;
    //     setBorder1('2px solid black');
    //     setBorder2('2px solid black');
    //     setBorder3('2px solid black');
    //     setBorder4('2px solid white');
    //     setFiller1('white');
    //     setFiller2('white');
    //     setFiller3('white');
    //     setFiller4('black');
    // }

    if(interior){
        newSubtotal += 20;
    }
    if(tyres){
        newSubtotal += 30;
    }

    newForms[props.index].subTotal = newSubtotal;
    props.setForms(newForms);
}

function Interior(clean){
    const newForms = [...props.forms];
    const type = newForms[props.index].carType;
    const tyres = newForms[props.index].polishTyres;
    let newSubtotal = 0;

    if(clean){
        newSubtotal += 20;
        newForms[props.index].cleanInterior = clean;
        setBorder5('2px solid white');
        setBorder6('2px solid black');
        setFiller5('black');
        setFiller6('white');
    }
    else{
        newForms[props.index].cleanInterior = clean;
        setBorder5('2px solid black');
        setBorder6('2px solid white');
        setFiller5('white');
        setFiller6('black');
    }
    if(tyres){
        newSubtotal += 30;
    }

    if(type == 'Light Vehicle'){
        newSubtotal += 80;
    }
    else if(type == '2 Axle Heavy Vehicle'){
        newSubtotal += 120;
    }
    else if(type == '3 & 4 Axle Heavy Vehicle'){
        newSubtotal += 250;
    }
    // else if(type == '5 & more Axle Heavy Vehicle'){
    //     newSubtotal += 450;
    // }

    newForms[props.index].subTotal = newSubtotal;
    props.setForms(newForms);
}

function Tyres(polish){
    const newForms = [...props.forms];
    const type = newForms[props.index].carType;
    const interior = newForms[props.index].cleanInterior;
    let newSubtotal = 0;

    if(polish){
        newSubtotal += 30;
        newForms[props.index].polishTyres = polish;
        setBorder7('2px solid white');
        setBorder8('2px solid black');
        setFiller7('black');
        setFiller8('white');
    }
    else{
        newForms[props.index].polishTyres = polish;
        setBorder7('2px solid black');
        setBorder8('2px solid white');
        setFiller7('white');
        setFiller8('black');
    }
    if(interior){
        newSubtotal += 20;
    }

    if(type == 'Light Vehicle'){
        newSubtotal += 80;
    }
    else if(type == '2 Axle Heavy Vehicle'){
        newSubtotal += 120;
    }
    else if(type == '3 & 4 Axle Heavy Vehicle'){
        newSubtotal += 250;
    }
    // else if(type == '5 & more Axle Heavy Vehicle'){
    //     newSubtotal += 350;
    // }

    newForms[props.index].subTotal = newSubtotal;
    props.setForms(newForms);
}

const output = (
                <>
                    <div className='card-label'><h1>Car {props.index+1}</h1></div>
                    <div className='booking-spec1'>
                        <div className='typeSpec'>
                            <h4>Select car type:</h4>
                            <div className='type-option'>
                                <div onClick={()=>CarType(1)} style={{border:border1 ,backgroundColor:filler1 }}></div>
                                <img src='/images/car.png' className='pic1'></img>
                                <p>Light Vehicle</p>
                                <p>ZAR 80</p>
                            </div>
                            <div className='type-option'>
                                <div onClick={()=>CarType(2)} style={{border:border2 ,backgroundColor:filler2 }}></div>
                                <img src='/images/2Truck.png' className='pic2'></img>
                                <p>2 Axle Heavy Vehicles</p>
                                <p>ZAR 120</p>
                            </div>
                            <div className='type-option'>
                                <div onClick={()=>CarType(3)} style={{border:border3 ,backgroundColor:filler3 }}></div>
                                <img src='/images/3Truck.png' className='pic3'></img>
                                <p>3&4 Axle Heavy Vehicles</p>
                                <p>ZAR 250</p>
                            </div>
                            {/* <div className='type-option'>
                                <div onClick={()=>CarType(4)} style={{border:border4 ,backgroundColor:filler4 }}></div>
                                <img src='/images/5Truck.png' className='pic4'></img>
                                <p>5 & more Axle Heavy Vehicles</p>
                                <p>ZAR 350</p>
                            </div> */}
                        </div>
                        <div className='interiorSpec'>
                            <h4>Clean Interior ?</h4>
                            <div className='interior-option'>
                                <div onClick={()=>Interior(true)} style={{border:border5 ,backgroundColor:filler5 }}></div>
                                <p>Yes</p>
                                <p>ZAR 20</p>
                            </div>
                            <div className='interior-option'>
                                <div onClick={()=>Interior(false)} style={{border:border6 ,backgroundColor:filler6 }}></div>
                                <p>No</p>
                                <p>-</p>
                            </div>
                        </div>
                        <div className='tyreSpec'>
                            <h4>Polish Tyres ?</h4>
                            <div className='tyre-option'>
                                <div onClick={()=>Tyres(true)} style={{border:border7 ,backgroundColor:filler7 }}></div>
                                <p>Yes</p>
                                <p>ZAR 30</p>
                            </div>
                            <div className='tyre-option'>
                                <div onClick={()=>Tyres(false)} style={{border:border8 ,backgroundColor:filler8 }}></div>
                                <p>No</p>
                                <p>-</p>
                            </div>
                        </div>
                    </div>
                </>)

return output;
}