/*
 * © 2023-2024 Nqobani Vuka Dlamini
 * All rights reserved.
 */
import { Outlet, Link } from "react-router-dom";
import "../Style/Account.css";
import { useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import Thumbnail from "../Components/Thumbnail";
import Thumbnail1 from "../Components/Thumbnail1";
import Booking from "../PopUps/Booking";
import Scheduling from "../PopUps/Scheduling";
import Chats from "../PopUps/chats";
import BookingsList from "../PopUps/BookingsList";
import UserEditor from "../PopUps/UserEditor";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { updateUser, signOut } from "../Actions/User";
import { updateServicer } from "../Actions/Servicer";
import { useNavigate } from "react-router-dom";
import ServicerEditor from "../PopUps/ServicerEditor";
import VideoCard2 from "../Components/VideoCard2";
import ImageCard from "../Components/ImageCard";
import AddImage from "../PopUps/AddImage";
import AddVideo from "../PopUps/AddVideo";
import AccountFlag from "../PopUps/AccountFlag";


export default function Account(){
    const linkN = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const linkP = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];
    const parameters = useParams();
    const {servicers, userData, sessionId, images, videos, ratings, reports, bookings} = useContext(UserDataContext);  
    const navigate = useNavigate();

    const [servicer, setServicer] = useState(null);
    const [shadow1, setShadow1] = useState('0px 0px 3px 0px black');
    const [shadow2, setShadow2] = useState('none');
    const [shadow3, setShadow3] = useState('none');
    const [shadow4, setShadow4] = useState('0px 0px 3px 0px black');
    const [shadow5, setShadow5] = useState('none');
    const [shadow6, setShadow6] = useState('none');
    const [Opacity1,setOpacity1] = useState('0.7');
    const [Opacity2,setOpacity2] = useState('0.6');
    const [Opacity3,setOpacity3] = useState('0.6');
    const [Opacity4,setOpacity4] = useState('0.7');
    const [Opacity5,setOpacity5] = useState('0.6');
    const [Opacity6,setOpacity6] = useState('0.6');
    const [selected, setSelected] = useState('previous');
    const [previous,setPrevious] = useState(null);
    const [upcoming,setUpcoming] = useState(null);
    const [saved,setSaved] = useState(null);

    const [booking, setBooking] = useState(null);
    const [message, setMessage] = useState(false);
    const [chats, setChats] = useState(false);
    const [bookingsOpen, setBookingsOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [view, setView] = useState('profile');
    const [addVideo, setAddVideo] = useState(false);
    const [addImage, setAddImage] = useState(false);
    const [rating, setRating] = useState([false,false,false,false,false]);
    const [scheduleOpen, setScheduleOpen] = useState(false);
    const [imageCollection, setImages] = useState([]);
    const [videoCollection, setVideos] =useState([]);
    const [jobs, setJobs] = useState(null);
    const [flagAccount, setFlag] = useState(false); // used for account holders that are blocked
    const [flagServicer, setFlag2] = useState(false); // used for flagged servicer accounts when users try to book them
    
    useEffect(()=>{
        if(!userData){
            navigate('/signIn');
        }
        else if(userData && userData.type=='Servicer' && !userData.idNumber){
            navigate('/signUp3');
        }
    },[userData])

    const DatePassed = (appointmentDate, currentDate)=>{
        const difference = currentDate - appointmentDate;
        return difference>0;
    }

    const Selection1 = async() =>{
        setShadow1('0px 0px 3px 0px black');
        setShadow2('none');
        setShadow3('none');
        setOpacity1('0.62');
        setOpacity2('0.6');
        setOpacity3('0.6');
        setSelected('previous');
    }
    const Selection2 = async() =>{
        setShadow1('none');
        setShadow2('0px 0px 3px 0px black');
        setShadow3('none');
        setOpacity1('0.6');
        setOpacity2('0.62');
        setOpacity3('0.6');
        setSelected('upcoming');
    }
    const Selection3 = async()=>{
        setShadow1('none');
        setShadow2('none');
        setShadow3('0px 0px 3px 0px black');
        setOpacity1('0.6');
        setOpacity2('0.6');
        setOpacity3('0.62');
        setSelected('saved');
    }

      const Selection4 = async() =>{
        setShadow4('0px 0px 3px 0px black');
        setShadow5('none');
        setShadow6('none');
        setOpacity4('0.62');
        setOpacity5('0.6');
        setOpacity6('0.6');
        setView('profile');
      }
      const Selection5 = async() =>{
        setShadow4('none');
        setShadow5('0px 0px 3px 0px black');
        setShadow6('none');
        setOpacity4('0.6');
        setOpacity5('0.62');
        setOpacity6('0.6');
        setView('images');
      }
      const Selection6 = async()=>{
        setShadow4('none');
        setShadow5('none');
        setShadow6('0px 0px 3px 0px black');
        setOpacity4('0.6');
        setOpacity5('0.6');
        setOpacity6('0.62');
        setView('videos');
      }

    function handleNull(){
    }

    function Save(){
        const saved = userData.saved.find((id)=>(id==servicer._id))
        if(!saved){
            updateUser(userData._id,{sessionId:sessionId,saved:[...userData.saved,servicer._id]})
        }
    }

    function Total(){
        const data = (selected=='previous')?previous:(selected=='upcoming')?upcoming:saved;

        if(data.length>8){
            return data.length;
        }
        else{
            return 8;
        }
    }

    function Prev(){
        const data = (selected=='previous')?previous:(selected=='upcoming')?upcoming:saved;
        if(data){
            for(let i=0; i<data.length; i++){
                linkP[(i+1)%Total()].current.click();
            }
        }
    }

    function Next(){
        const data = (selected=='previous')?previous:(selected=='upcoming')?upcoming:saved;
        if(data){
            for(let i=0; i<data.length; i++){
                linkN[(i+1)%Total()].current.click();
            }
        }  
    } 
    useEffect(()=>{
        if(parameters.servicerId && servicers){
            const data = servicers.find((servicerData)=>(servicerData._id==parameters.servicerId))
            setServicer(data);
        }
    },[servicers, parameters])

    useEffect(()=>{
        if(userData && ratings && (userData.type=='Servicer' || servicer)){
            const ratingArr = (parameters.accountType=='user')?ratings.filter((rating)=>rating.servicerId==userData._id):ratings.filter((rating)=>rating.servicerId==servicer._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;
            
            switch(ratingAve){
                case 0:
                    setRating([false,false,false,false,false]);
                    break;
                case 1:
                    setRating([true,false,false,false,false]);
                    break;
                case 2:
                    setRating([true,true,false,false,false]);
                    break;
                case 3:
                    setRating([true,true,true,false,false]);
                    break;
                case 4:
                    setRating([true,true,true,true,false]);
                    break;
                case 5:
                    setRating([true,true,true,true,true]);
                    break;
            }
        }
    },[ratings,parameters,servicer,userData])

    useEffect(()=>{
        if(userData && userData.type=='User' && parameters.accountType=='user' && bookings){
            
            let previousData = bookings.filter((data)=>(data.bookerId==userData._id && (data.completedDate!=null || DatePassed(new Date(data.appointment.dateTime),new Date()) == true ) ));
            let upcomingData = bookings.filter((data)=>(data.bookerId==userData._id && data.paidDate!=null && data.completedDate==null && DatePassed(new Date(data.appointment.dateTime),new Date()) == false));
            let savedData = userData.saved.filter((data)=>data!='');

            setPrevious(previousData.sort((a, b) => (a && b && (new Date(a.appointment.dateTime) - new Date(b.appointment.dateTime)) )));
            setUpcoming(upcomingData.sort((a, b) => (a && b && (new Date(a.appointment.dateTime) - new Date(b.appointment.dateTime)))));
            setSaved(savedData);
        }
    },[userData,parameters, bookings])

    useEffect(()=>{
        if(images && videos && userData && userData.type=='Servicer'){
            const data1 = images.filter((image)=>image.userId == userData._id);
            const data2 = videos.filter((video)=>video.userId == userData._id);

            setImages(data1);
            setVideos(data2);
        }
        else if(images && videos && userData && userData.type=="User" && servicer){
            const data1 = images.filter((image)=>image.userId == servicer._id);
            const data2 = videos.filter((video)=>video.userId == servicer._id);

            setImages(data1);
            setVideos(data2);
        }
    },[images, videos, servicer, userData])

    //Count the number of bookings completed by the servicer
    useEffect(()=>{
        if(bookings && servicer){
            const data = bookings.filter((booking)=>booking.servicerId == servicer._id && booking.progress.stage4a==true && booking.progress.stage4b==true);
            setJobs(data.length);
        }
        else if(bookings && userData.type=='Servicer'){
            const data = bookings.filter((booking)=>booking.servicerId == userData._id && booking.progress.stage4a==true && booking.progress.stage4b==true);
            setJobs(data.length);
        }
    },[bookings, servicer, userData])

    useEffect(()=>{
        if(jobs){
            console.log('Jobs: ',jobs);
        }
    },[jobs])

    useEffect(()=>{
        if(reports && userData){
            // console.log("Reports", reports)
            // console.log(userData._id)
            const myReports = reports.filter((report)=>report.offenderId == userData._id);
            // console.log(myReports.length)
            if(myReports.length>9){
                // console.log("Account Flagged");
                setFlag(true)
                if(userData.flagged==null || userData.flagged==false){
                    if(userData.type=='Servicer'){
                        // console.log("Servicer Account Flagged");
                        updateServicer(userData._id,{flagged:true, sessionId:sessionId});
                    }
                    else{
                        // console.log("User Account Flagged");
                        updateUser(userData._id,{flagged:true, sessionId:sessionId});
                    }
                }

                return
            }
        }
        if(reports && servicer && parameters && parameters.accountType=='servicer'){
            const theirReports = reports.filter((report)=>report.offenderId == servicer._id);

            if(theirReports.length>9){
                console.log("Their Account Flagged");
                setFlag2(true);
                if(servicer.flagged==null || servicer.flagged==false){    
                    updateServicer(servicer._id,{flagged:true, sessionId:sessionId});
                }
            }
        }
    },[reports, userData, parameters, servicer])

    const output = (<div id="Account">
                        {booking!=null &&(<>
                            <Booking serviceType={booking} setBooking={setBooking} servicer={servicer}></Booking>
                        </>)}
                        {userData && scheduleOpen==true &&(<>
                            <Scheduling serviceType={userData.service} setScheduleOpen={setScheduleOpen} ></Scheduling>
                        </>)}
                        {chats==true &&(<>
                            <Chats setChats={setChats} servicer={servicer}></Chats>
                        </>)}
                        {bookingsOpen==true &&(<>
                            <BookingsList setBookingsOpen={setBookingsOpen}></BookingsList>
                        </>)}
                        {edit==true && userData && userData.type=='User' &&(
                            <UserEditor setEdit={setEdit}></UserEditor>
                        )}
                        {edit==true && userData && userData.type=='Servicer' &&(
                            <ServicerEditor setEdit={setEdit}></ServicerEditor>
                        )}
                        {parameters && parameters.accountType && addImage==true && (
                            <AddImage servicer={servicer} accountType={parameters.accountType} setAddImage={setAddImage}></AddImage>
                        )}
                        {parameters && parameters.accountType && addVideo==true && (
                            <AddVideo servicer={servicer} accountType={parameters.accountType} setAddVideo={setAddVideo}></AddVideo>
                        )}
                        {flagAccount==true && (
                            <AccountFlag setFlag={setFlag} type={'Account'}></AccountFlag>
                        )}
                        {flagServicer==true && (
                            <AccountFlag setFlag={setFlag2} type={'Servicer'}></AccountFlag>
                        )}
                        {parameters && parameters.accountType=='user' && userData && userData.type=='User' &&(<>   
                            <div className="userdata-block">
                                <div className="left-block">
                                    <div className="account-frame">
                                        <img src={(userData.profileLink && userData.profileLink!='')?userData.profileLink:"/Images/user.png"} className="user-image"></img>
                                        <img src="/Images/pencil.png" className="edit-user" onClick={()=>setEdit(true)}></img>
                                    </div>
                                    <button onClick={()=>signOut({sessionId:sessionId})}>Sign Out</button>
                                </div>
                                <div className="right-block">
                                    <div className="location">
                                        <img src="/Images/pin.png" ></img>
                                        <p>{(userData.city)?userData.city:'-'}</p>
                                    </div>
                                    <p className="user-name">{userData.name} {userData.surname}</p>
                                    <p className="user-email">{userData.email}</p>
                                </div>
                            </div>
                            <div className="selection-block1">
                                <div className="selection1" onClick={Selection1} style={{opacity: Opacity1, boxShadow: shadow1}}><p>Previous Bookings</p></div>
                                <div className="selection2" onClick={Selection2} style={{opacity: Opacity2, boxShadow: shadow2}}><p>Upcoming Bookings</p></div>
                                <div className="selection3" onClick={Selection3} style={{opacity: Opacity3, boxShadow: shadow3}}><p>Saved</p></div>
                            </div>
                            <div className="account-slideshow">
                                <div className="slide-block1"><div onClick={Prev}><img src="/Images/left2.png"></img></div></div>
                                <div className="slide-block2">
                                    {selected == 'previous' && previous && previous.map((data,index)=>
                                        <Thumbnail1 linkN={linkN[(index+1)%Total()]} linkP={linkP[(index+1)%Total()]} total={Total} pos={(index+1)%Total()} data={data}></Thumbnail1>
                                    )}
                                    {selected == 'upcoming' && upcoming && upcoming.map((data,index)=>
                                        <Thumbnail1 linkN={linkN[(index+1)%Total()]} linkP={linkP[(index+1)%Total()]} total={Total} pos={(index+1)%Total()} data={data}></Thumbnail1>
                                    )}
                                    {selected == 'saved' && saved && saved.map((data,index)=>
                                        <Thumbnail linkN={linkN[(index+1)%Total()]} linkP={linkP[(index+1)%Total()]} total={Total} pos={(index+1)%Total()} data={data}></Thumbnail>
                                    )}
                                </div>
                                <div className="slide-block3"><div onClick={Next}><img src="/Images/next2.png"></img></div></div>
                            </div>
                        </>)}
                        {parameters && parameters.accountType=='user' && userData.type=='Servicer' &&(<>
                            <div className="selection-block2">
                                <div className="selection1" onClick={Selection4} style={{opacity: Opacity4, boxShadow: shadow4}}><img src='/Images/profile2.png'></img></div>
                                <div className="selection2" onClick={Selection5} style={{opacity: Opacity5, boxShadow: shadow5}}><img src='/Images/grid2.png'></img></div>
                                <div className="selection3" onClick={Selection6} style={{opacity: Opacity6, boxShadow: shadow6}}><img src='/Images/video3.png'></img></div>
                            </div>
                            {view=='profile' &&(
                                <div className="profile">
                                    <div className="account-frame2">
                                        <img src={(userData.profileLink && userData.profileLink!='')?userData.profileLink:"/Images/user.png"} className="af-img"></img>
                                        <img src="/Images/pencil.png" className="edit-servicer" onClick={()=>setEdit(true)}></img>
                                    </div>
                                    <p className="service-type">
                                        <span class="dot1">.</span>
                                        {(userData.service)?userData.service:'-'}
                                        <span class="dot2">.</span>
                                    </p>
                                    <div className="stars-horizontal">
                                        <img src={(rating[0])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[1])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[2])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[3])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[4])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                    </div>
                                    <div className="servicerData">
                                        <div>
                                            <p className="jobs-tally">{(jobs)?jobs:'0'} {(jobs && jobs==1)?'Job':'Jobs'}</p>
                                        </div>
                                        <div className="location">
                                            <img src="/Images/pin.png"></img>
                                            <p >{(userData.city)?userData.city:'-'}</p>
                                        </div>
                                    </div>
                                    <h1 className="servicer-name">{userData.name} {userData.surname}</h1>
                                    <div className="servicer-buttons">
                                        <button onClick={()=>signOut({sessionId:sessionId})}>Sign Out</button>
                                        <button onClick={()=>setScheduleOpen(true)}>Schedule</button>
                                        <button onClick={()=>setBookingsOpen(true)}>Bookings</button>
                                        <button onClick={()=>setChats(true)}>Messages</button>
                                    </div>
                                    <p className="servicer-description">{(userData.description)?userData.description:''}</p>
                                </div>
                            )}
                            {view=='images' &&(
                                <div className="image-grid">
                                    <div className="plus" onClick={()=>setAddImage(true)}><img src="/Images/plus.png"></img></div>
                                    {imageCollection && imageCollection.map((data)=>
                                        <ImageCard data={data} owner={true}></ImageCard>
                                    )}
                                </div>
                            )}
                            {view=='videos' &&(
                                <div className="video-grid">
                                    <div className="plus" onClick={()=>setAddVideo(true)}><img src="/Images/plus.png"></img></div>
                                    {videoCollection && videoCollection.map((data)=>
                                        <VideoCard2 data={data} owner={true}></VideoCard2>
                                    )}
                                </div>
                            )}
                        </>)}
                        {parameters && parameters.accountType=='servicer' && servicer &&(<>
                            <div className="selection-block2">
                                <div className="selection1" onClick={Selection4} style={{opacity: Opacity4, boxShadow: shadow4}}><img src='/Images/profile2.png'></img></div>
                                <div className="selection2" onClick={Selection5} style={{opacity: Opacity5, boxShadow: shadow5}}><img src='/Images/grid2.png'></img></div>
                                <div className="selection3" onClick={Selection6} style={{opacity: Opacity6, boxShadow: shadow6}}><img src='/Images/video3.png'></img></div>
                            </div>
                            {view=='profile' &&(
                                <div className="profile">
                                    <div className="account-frame2">
                                        <img src={(servicer.profileLink && servicer.profileLink!='')?servicer.profileLink:"/Images/user.png"} className="af-img"></img>
                                    </div>
                                    <p className="service-type">
                                        <span class="dot1">.</span>
                                        {(servicer.service)?servicer.service:'-'}
                                        <span class="dot2">.</span>
                                    </p>
                                    <div className="stars-horizontal">
                                        <img src={(rating[0])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[1])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[2])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[3])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                        <img src={(rating[4])?"/Images/star-yellow.png":"/Images/star.png"}></img>
                                    </div>
                                    <div className="servicerData">
                                        <div>
                                            <p className="jobs-tally">{(jobs)?jobs:'0'} {(jobs && jobs.length==1)?'Job':'Jobs'}</p>
                                        </div>
                                        <div className="location">
                                            <img src="/Images/pin.png"></img>
                                            <p >{(servicer.city)?servicer.city:'-'}</p>
                                        </div>
                                    </div>
                                    <h1 className="servicer-name">{servicer.name} {servicer.surname}</h1>
                                    <div className='servicer-buttons'>
                                        <button className="save-button" onClick={(userData.type=='User')?Save:handleNull}>Save</button>
                                        <button className="book-button" onClick={()=>(userData.type=='User')?setBooking(servicer.service):handleNull}>Book</button>
                                    </div>
                                    <p className="servicer-description">{(servicer.description)?servicer.description:''}</p>
                                </div>
                            )}
                            {view=='images' &&(
                                <div className="image-grid">
                                    {imageCollection && imageCollection.map((data)=>
                                        <ImageCard data={data} owner={false}></ImageCard>
                                    )}
                                </div>
                            )}
                            {view=='videos' &&(
                                <div className="video-grid">    
                                    {videoCollection && videoCollection.map((data)=>
                                        <VideoCard2 data={data} owner={false}></VideoCard2>    
                                    )}
                                </div>
                            )}
                        </>)}

                    </div>);

    return output;
}