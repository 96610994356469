import '../Style/Faqs.css';
import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { UserDataContext } from '../Data/Session';
import AddFaq from '../PopUps/AddFaq';
import DeleteFaq from '../PopUps/DeleteFaq';
import UpdateFaq from '../PopUps/UpdateFaq';

export default function Faqs(){
    const navigate = useNavigate();
    const [faqOpen, setFaqOpen] = useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]);
    const [addFaq, setAddFaq] = useState(false);
    const {faqs, userData} = useContext(UserDataContext);
    const [deleteFaq, setDeleteFaq] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(0);
    const [updateFaq, setUpdateFaq] = useState(false);
    const [toBeUpdated, setToBeUpdated] = useState(0);

    const updateIndex = (index, newValue) => {
        setFaqOpen(prevArray => {
          const newArray = [...prevArray]; // Create a shallow copy of the array
          newArray[index] = newValue;      // Update the specific index
          return newArray;                 // Return the new array with the updated value
        });
    };

    // useEffect(()=>{
    //     if(faqs){
    //         console.log('Faqs: ',faqs);
    //     }
    // },[faqs])

    const output = (
        <>
        {addFaq && addFaq==true && (
            <AddFaq setAddFaq={setAddFaq}></AddFaq>
        )}
        {faqs && deleteFaq && deleteFaq==true &&(
            <DeleteFaq setDelete={setDeleteFaq} id={faqs[toBeDeleted]._id}></DeleteFaq>
        )}
        {faqs && updateFaq && updateFaq==true &&(
            <UpdateFaq setUpdateFaq={setUpdateFaq} faq={faqs[toBeUpdated]}></UpdateFaq>
        )}
        <div id="Faqs">
            <div className="logo-block">
                <img src="/Images/logo3.png"></img>
            </div>
            <button className='backButton' onClick={()=>navigate(-1)}>&#8592; Back</button>
            <button className='addButton' onClick={()=>setAddFaq(true)} style={{display:(userData && userData.email && userData.email=='dlamininqobani27@gmail.com')?'block':'none'}}><img src="/Images/plus.png"></img></button>
            <div className='document'>
                <h2>{'Frequently Asked Questions (FAQ)'}</h2>
                {faqs && faqs.map((faq, index)=>
                    <div className="faq-section" onClick={()=>updateIndex(index,!faqOpen[index])}>
                        <img className='updateFaq' src='/Images/pencil.png' onClick={()=>{setUpdateFaq(true);setToBeUpdated(index);}} style={{display:(userData && userData.email && userData.email=='dlamininqobani27@gmail.com')?'block':'none'}}></img>
                        <img className='deleteFaq' src='/Images/delete.png' onClick={()=>{setDeleteFaq(true);setToBeDeleted(index);}} style={{display:(userData && userData.email && userData.email=='dlamininqobani27@gmail.com')?'block':'none'}}></img> 
                        <p className='faq-title'>
                            {index+1}. {faq.question}
                        </p>
                        <p className={`faq-text ${(faqOpen[index]==true)?'faqOn':'faqOff'}`}>{faq.answer}</p> 
                    </div>
                )}
            </div>
        </div>
        </>
    );

    return output;
}