import '../Style/Terms.css';
import { useNavigate } from 'react-router-dom';

export default function Terms(){

    const navigate = useNavigate();

    const output = (
        <div id="Terms">
            <div className="logo-block">
                <img src="/Images/logo3.png"></img>
            </div>
            <button className='backButton' onClick={()=>navigate(-1)}>&#8592; Back</button>
            <div className='document'>
                <h2>{'Terms and Conditions (Ts&Cs)'}</h2>
                <div className='term-section'>
                    <p className='term-title'>1. Introduction</p>
                    <p className='term-text'>Welcome to ScheduleEase! By accessing or using our platform, you agree to these Terms and Conditions. If you do not agree with any part of these terms, you must refrain from using the platform.</p>
                    <p className='term-text'>ScheduleEase is a service platform facilitating connections between users seeking basic services and independent service providers. Payments are handled securely through Peach Payments.</p>
                </div>
                <div className='term-section'>
                    <p className='term-title'>2. Definitions</p>
                    <ul>
                        <li><span className='bold'>"Platform"</span> refers to the ScheduleEase website and mobile applications.</li>
                        <li><span className='bold'>"Users"</span> refers to individuals accessing ScheduleEase, including service seekers and service providers.</li>
                        <li><span className='bold'>"We/Us/Our"</span> refers to ScheduleEase.</li>
                        <li><span className='bold'>"Service Providers"</span> are individuals offering services via the platform.</li>
                        <li><span className='bold'>"Payment Gateway"</span> refers to Peach Payments, which processes card transactions securely.</li>
                        <li><span className='bold'>"PIOPA"</span> refers to the Protection of Personal Information Act, 2013 (Act No. 4 of 2013)</li>
                        <li><span className='bold'>"CPA"</span> refers to the Consumer Protection Act, 2008 (Act No. 68 of 2008)</li>
                        <li><span className='bold'>"PCI/PCI DSS"</span> refers to the Payment Card Industry Data Security Standard</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>3. User Eligibility</p>
                    <ul>
                        <li>Users must be at least 18 years old to use the platform.</li>
                        <li>Service Providers must provide valid South African identification numbers during registration and pass a criminal background check.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>4. Account Responsibilities</p>
                    <ul>
                        <li>Users are responsible for maintaining the confidentiality of their login credentials.</li>
                        <li>Users must provide accurate and truthful information when creating accounts.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>5. Services Provided</p>
                    <ul>
                        <li>ScheduleEase acts as an intermediary, connecting users with service providers.</li>
                        <li>ScheduleEase does not directly provide the services listed on the platform.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>6. Payments</p>
                    <ul>
                        <li>All payments are processed through Peach Payments using secure 3D Secure Authorization (OTP or biometrics).</li>
                        <li>We "outsourced" all sensitive data handling functions to a certified third party.</li>
                        <li>Users can choose between card payments or cash options.</li>
                        <li>Card data is tokenized, and we only store tokens, card type, last 4 digits, and expiry dates.</li>
                        <li>Liability for card payments rests with the card issuer (Visa, Mastercard, etc.).</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>7. Refund Policy</p>
                    <p className='term-text'>1. Voluntary Cancellation by the User:</p>
                    <ul>
                        <li>If a user cancels their booking for any reason other than servicer lateness, they are eligible for a refund of <span className='bold'>95% of the booking amount</span>.</li>
                        <li>The remaining 5% is retained as an administrative fee.</li>
                    </ul>
                    <p className='term-text'>2. Cancellation Due to Servicer Lateness:</p>
                    <ul>
                        <li>If the user cancels the booking due to the servicer arriving more than 10 minutes late without prior communication, the user is eligible for a <span className='bold'>100% refund</span> of the booking amount.</li>
                    </ul>
                    <p className='term-text'>3. Servicer-Initiated Cancellations:</p>
                    <ul>
                        <li>In the event that the servicer cancels a confirmed booking, the user is eligible for a<span className='bold'> 100% refund </span>of the booking amount.</li>
                    </ul>
                    <p className='term-text'>4. Refund Processing:</p>
                    <ul>
                        <li>Refunds will be processed within <span className='bold'>5-7 business days</span> of the cancellation request.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>8. Cancellation Policy</p>
                    <p className='term-text'>ScheduleEase's cancellation terms are outlined in our Refund Policy above. Please refer to the Refund Policy section (clause 7 above) for details on cancellations, applicable fees, and refund eligibility.</p>
                </div>
                <div className='term-section'>
                    <p className='term-title'>9. User Conduct</p>
                    <ul>
                        <li className='bold'>Users must not engage in illegal, abusive, or fraudulent activities on the platform.</li>
                        <li className='bold'>Service Providers must deliver services professionally and as described in their profiles.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>10. Data Protection</p>
                    <ul>
                        <li>ScheduleEase complies with the Protection of Personal Information Act (POPIA) and ensures the secure handling of user data.</li>
                        <li>Refer to our Privacy Policy for detailed information.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>11. Trademarks</p>
                    <ul>
                        <li className='bold'>All logos, trademarks, and branding related to ScheduleEase are the property of ScheduleEase.</li>
                        <li className='bold'>Users may not use any of ScheduleEase's trademarks, logos, or branding without explicit written permission from ScheduleEase. Unauthorized use may result in legal action.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>12. Ratings and Reviews</p>
                    <ul>
                        <li>Service ratings, including star ratings, comments, and creation dates, are visible to all users.</li>
                        <li>Raters' names, surnames, and profile pictures will be displayed alongside their reviews.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>13. Liability and Disputes</p>
                    <ul>
                        <li>ScheduleEase provides a platform to facilitate connections between users and service providers and performs identity checks on service providers in an attempt to avoid occurences of fraudulent activities.</li>
                        <li className='bold'>ScheduleEase is not liable for any harm, damages, or misconduct that may occur during interactions or transactions between users and service providers.</li>
                        <li>Users acknowledge that all engagements, service agreements, and disputes are solely between the user and the service provider.</li>
                        <li>While we strive to ensure a high standard of service through vetting, all liability for service delivery rests with the individual service provider.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>14. Termination</p>
                    <ul>
                        <li>Users can delete their accounts at any time.</li>
                        <li>ScheduleEase reserves the right to suspend or terminate accounts that breach these terms.</li>
                    </ul>
                </div>
                <div className='term-section'>
                    <p className='term-title'>15. Governing Law</p>
                    <p className='term-text bold'>These Terms and Conditions are governed by the following laws and standards:</p>
                    <ul className='numerical-points'>
                        <li><span className='bold'>Protection of Personal Information Act, 2013 (Act No. 4 of 2013):</span> This South African law regulates the processing of personal information to ensure privacy and data security. It was signed into law on 19 November 2013 and came into effect on 1 July 2020.</li>
                        <li><span className='bold'>Consumer Protection Act, 2008 (Act No. 68 of 2008):</span> This South African law aims to promote a fair, accessible, and sustainable marketplace for consumer products and services. It was signed into law on 24 April 2009 and came into effect on 1 April 2011.</li>
                        <li><span className='bold'>Payment Card Industry Data Security Standard (PCI DSS):</span> These international security standards, established by the PCI Security Standards Council in 2004, are designed to protect cardholder data during payment processing.</li>
                    </ul>
                </div>
            </div>

        </div>
    );

    return output;
}